// @file composable for high contrast mode

import { AccessibilitySettingPreference } from '@@/enums'
import type { User, UserCamelCase } from '@@/types'
import { useAccessibilitySettings } from '@@/vuecomposables/useAccessibilitySettings'
import { createSharedComposable, usePreferredContrast } from '@vueuse/core'
import type { Ref } from 'vue'
import { computed, ref, watch } from 'vue'

export const useHighContrastMode = createSharedComposable((user?: Ref<User | UserCamelCase | undefined | null>) => {
  const { highContrastModePreference: userPreferredContrast } = useAccessibilitySettings(user)
  const systemPreferredContrast = usePreferredContrast()
  const customPreferredContrast = ref<AccessibilitySettingPreference | null>(null)
  /**
   * Computed value that determines if high contrast mode should be enabled
   * Checks in order of precedence:
   * 1. Page-specific override (if set)
   * 2. User preference (if set)
   * 3. System preference
   */
  const isInHighContrastMode = computed(() => {
    if (customPreferredContrast.value != null) {
      if (customPreferredContrast.value === AccessibilitySettingPreference.System) {
        return systemPreferredContrast.value === 'more'
      }
      return customPreferredContrast.value === AccessibilitySettingPreference.On
    }

    // User preference takes precedence when set and not 'system'
    if (userPreferredContrast.value != null && userPreferredContrast.value !== AccessibilitySettingPreference.System) {
      return userPreferredContrast.value === AccessibilitySettingPreference.On
    }

    // Fall back to system preference
    return systemPreferredContrast.value === 'more'
  })

  const setCustomPreferredContrast = (contrast: AccessibilitySettingPreference): void => {
    customPreferredContrast.value = contrast
  }

  watch(
    isInHighContrastMode,
    (isHighContrastMode) => {
      document.documentElement.dataset.highContrastMode = isHighContrastMode ? 'true' : 'false'
      if (isHighContrastMode) {
        import('@@/styles/high_contrast.css')
      }
    },
    { immediate: true },
  )

  return {
    isInHighContrastMode,
    setCustomPreferredContrast,
  }
})
